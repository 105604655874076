import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bxs-dashboard',
        link: '',
    },
    {
      id: 3,
      label: 'MENUITEMS.ORDERS.TEXT',
      icon: 'bx bxs-dashboard',
    },
    {
      id: 3,
      label: 'MENUITEMS.PRODUCTS.TEXT',
      icon: 'bx bxs-dashboard',
      subItems: [
        {
          id: 4,
          label: 'MENUITEMS.PRODUCTS.LIST.ALL',
          link: '/pages/products',
          parentId: 3
        },

      ],
    }
];

