import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IOrderItem} from '../../../models/order_items';
import {OrderManagementService} from '../../../services/order-management.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})

/**
 * Order Component
 */
export class OrderComponent implements OnInit {

  @ViewChild('orderview') order: any;

  orderId?: number;
  extOrderNo?: string;
  orderDate?: Date;
  total?: number;
  custName?: string;
  orderStatus?: string;
  pmtStatus?: string;
  custId?: number;
  showReadyBtn?: boolean;
  orderCurrentStatus?: string;
  notificationId?: number;

  orderItems: IOrderItem[] = [];

  @Input() orders: Array<{
    order_id?: number;
    ext_order_no: string;
    cust_id?: number;
    last_name?: string;
    first_name?: string;
    order_date?: Date;
    order_status?: string;
    ref_number?: string;
    sub_total?: number;
    tax_rate?: number;
    order_tax?: number;
    order_total?: number;
    payment_status?: string;
  }> | undefined;

  constructor(private modalService: NgbModal,
              private orderService: OrderManagementService) { }

  ngOnInit(): void {
    /* getting in process orders */


  }

  // tslint:disable-next-line:max-line-length
  onEditOrder(order: { order_id?: number; ext_order_no?: string; cust_id?: number; last_name?: string; first_name?: string; order_date?: Date; order_status?: string; ref_number?: string; sub_total?: number; tax_rate?: number; order_tax?: number; order_total?: number; payment_status?: string; order_pmt_token?: string; }): void{
    console.log(order);
    // @ts-ignore
    this.orderId = order.order_id;
    this.extOrderNo = order.ref_number;
    this.orderDate = order.order_date;
    this.custName = order.first_name + ' ' + order.last_name;
    this.orderStatus = order.order_status;
    this.pmtStatus = order.payment_status;
    this.total = order.order_total;
    this.custId = order.cust_id;
    // get order items;
    this.orderService.getOrderItems(this.orderId)
      .subscribe(
        (data) => {
          this.orderItems = data;
          console.log('customer details: ' + JSON.stringify(this.orderItems));
        },
        (err: any) => {
        },
        () => console.log('All done getting customer orders')
      );
    this.modalService.open(this.order, { centered: true });
  }

  onChangeOrderStatus(statusId: number): void {
    console.log(statusId);
    console.log('Order status: ' + this.orderStatus);
    this.orderService.updateOrderStatus(this.orderId, statusId)
      .subscribe((resp) => {
        console.log('conChangeOrderStatus() - response ' + JSON.stringify(resp));
        this.onSendingNotification(statusId);
        this.onRefreshPendingOrders();
      });
    this.modalService.dismissAll();
  }

  onRefreshPendingOrders(): void {
    window.location.reload();
  }

  onSendingNotification(sid: number): void {

    console.log('oonSendingNotification..');
    this.notificationId = 0;
    if (sid === 7) {
      this.notificationId = 5;
    }
    if (sid === 8) {
      this.notificationId = 6;
    }
    console.log('Notification ID:' + this.notificationId);
    if (this.notificationId !== 0) {
      this.orderService.sendSystemNotification(this .notificationId, this.custId, this.orderId)
        .subscribe((resp) => {
          console.log('onSendingNotification()- response: ' + JSON.stringify(resp));
        });
    }

  }

  onSearchOrder(orderToken: string): void {

  }

}
