import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bxs-dashboard',
        link: '',
    },
    {
        id: 2,
        label: 'MENUITEMS.ORDERS.TEXT',
        icon: 'bx bx-briefcase',
        link: '/orders',

    },
    {
        id: 3,
        label: 'MENUITEMS.PRODUCTS.TEXT',
        icon: 'bx bxs-grid me-2',
        link: '/products',

    },

];

