import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IOrder } from '../models/orders';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IOrderItem } from '../models/order_items';
import { IDashboardStats } from '../models/dashboard_stats';

@Injectable({
  providedIn: 'root'
})
export class OrderManagementService {

  private apiUrl = 'https://whiskeytest.ariketasystems.com/public/api/';
  private resourceURL: string | undefined;
  private queryParams: string | undefined;
  private endPoint: string | undefined;
  private bodyStr: string | undefined;
  constructor(private http: HttpClient) { }

  getOrderItems(orderId: number | undefined): Observable<IOrderItem[]> {

    this.resourceURL = 'dashboardorders/' + orderId;
    this.endPoint = this.apiUrl + this.resourceURL;
    console.log(this.endPoint);
    return this.http.get<IOrderItem[]>(this.endPoint)
      .pipe(
        tap(items => {
          console.log('Customer Order from service: ' + JSON.stringify(items));
        }),
        catchError(this.handleError)
      );
  }

  getDashboardStatsByCalendar(calendarId: number | undefined): Observable<IDashboardStats[]> | null{

    this.resourceURL = 'dashboardstats/' + calendarId;
    this.endPoint = this.apiUrl + this.resourceURL;
    console.log(this.endPoint);

    return null;
  }

  getDashboardStats(): Observable<IDashboardStats[]> | null{

    this.resourceURL = 'dashboardstats';
    this.endPoint = this.apiUrl + this.resourceURL;
    console.log(this.endPoint);

    return this.http.get<IDashboardStats[]>(this.endPoint)
      .pipe(
        tap(stats => {
          console.log('Stats: ' + JSON.stringify(stats));
        }),
        catchError(this.handleError)
      );
  }

  getPendingOrders(): Observable<IOrder[]>{
    this.resourceURL = 'dashboard';
    this.endPoint = this.apiUrl + this.resourceURL;
    console.log(this.endPoint);
    return this.http.get<IOrder[]>(this.endPoint)
      .pipe(
        tap(data => console.log('Data Loaded')),
        catchError(this.handleError)
      );
  }

  private handleError(err: any): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    return throwError(errorMessage);
  }

  updateOrderStatus(orderId: number | undefined, statusId: number | undefined): Observable<any> {
    const cHeader = new HttpHeaders()
      .set('Content-Type', 'application/json');
    this.resourceURL = 'orders/' + orderId;
    this.endPoint = this.apiUrl + this.resourceURL;
    console.log('updateOrderStatus from service: ' + this.endPoint);
    this.bodyStr = '{ "order_status_id": ' + statusId + '}';

    console.log('updateCustomer() body : ' + this.bodyStr);

    return this.http.put<any>(this.endPoint, this.bodyStr, {headers: cHeader})
      .pipe(
        tap(resp => {
          console.log('updating customer API response : ' + JSON.stringify(resp));
        }),
        catchError(this.handleError)
      );
  }

  sendSystemNotification(nid: number, cid: number | undefined, oid: number | undefined): Observable<any>{

    const cHeader = new HttpHeaders()
      .set('Content-Type', 'application/json');

    this.resourceURL = 'sendnotification';
    this.queryParams = '?notification_id=' + nid + '&cust_id=' + cid + '&order_id=' + oid;
    this.endPoint = this.apiUrl + this.resourceURL + this.queryParams;

    return this.http.get<any>(this.endPoint)
      .pipe(
        tap(notification => {
          console.log('Notification Service: ' + JSON.stringify(notification));
        }),
        catchError(this.handleError)
      );
  }


}
