import { Component, OnInit, ViewChild } from '@angular/core';
import { emailSentBarChart, monthlyEarningChart, transactions, orders, users } from './data';
import { ChartType } from './dashboard.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderManagementService } from '../../services/order-management.service';
import {IDashboardStats} from '../../models/dashboard_stats';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardsComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  interval: any;
  emailSentBarChart!: ChartType;
  monthlyEarningChart!: ChartType;
  transactions: any;
  orders: any;
  stats: IDashboardStats[] = [];
  users: any;
  currentStats!: IDashboardStats;
  ordersInProcess = 0;
  ordersCompleted = 0;
  totalSubscribers = 0;
  totalSales = 0;

  @ViewChild('content') content: any;



  errorMessage = '';
  constructor(private modalService: NgbModal,
              private orderService: OrderManagementService) { }

  ngOnInit(): void {

    // BreadCrumb
    this.breadCrumbItems = [
      { label: 'Dashboard' },
      { label: 'Dashboard', active: true }
    ];

    this.fetchData();
    this.interval = setInterval(() => {
      this.getDashboardOrders();
      this.getDashboardStats();
    }, 10000);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.openModal();
    }, 2000);
  }

  /**
   * Fetches the data
   */
  private fetchData() {
    this.emailSentBarChart = emailSentBarChart;
    this.monthlyEarningChart = monthlyEarningChart;
    this.transactions = transactions;
    this.getDashboardOrders();
    this.getDashboardStats();

    this.users = users;
  }

  private getDashboardStats(): void{
    // @ts-ignore
    this.orderService.getDashboardStats().subscribe({
      error: err => {
        this.errorMessage = err;
      },
      next: dashboardStats => {
        this.stats = dashboardStats;
        // @ts-ignore
        this.ordersInProcess = this.stats[0].orders_inprocess;
        // @ts-ignore
        this.ordersCompleted = this.stats[0].orders_completed;
        // @ts-ignore
        this.totalSales = this.stats[0].total_sales;
        // @ts-ignore
        this.totalSubscribers = this.stats[0].subscribers;
      }
    });


  }

  private getDashboardOrders(): void{
    this.orderService.getPendingOrders().subscribe({
      next: inventoryItems => {
        this.orders = inventoryItems;
      },
      error: err => { this.errorMessage = err; }
    });

  }

  /***
   * Subscribe Model open
   */

  openModal() {
    // this.modalService.open(this.order, { centered: true });
  }
}
