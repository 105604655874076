<form class="app-search d-none d-lg-block">
  <div class="position-relative">
    <input type="text" class="form-control" placeholder="SEARCH ORDER">
    <span class="mdi mdi-magnify"></span>
  </div>
</form>
<div class="table-responsive mb-0">
  <table class="table align-middle table-striped table-nowrap mb-0">
    <tbody>
      <tr *ngFor="let order of orders">
        <td># {{ order.order_id }}</td>
        <td>{{ order.ref_number }}</td>
        <td>
          <span
            class="badge rounded-pill bg-success bg-soft text-success"
            [ngClass]="{
              'text-danger bg-danger': order.order_status === 'Processing',
              'text-warning bg-warning': order.order_status === 'Ready',
              'text-success bg-success': order.order_status === 'Picked up'
            }"
            >{{ order.order_status }}</span
          >
        </td>
        <td>
          {{ order.order_total | currency:'USD':true:'1.2-2' }}
        </td>
        <td>
          {{ order.order_date | date: 'MMM d, y'}}
        </td>
        <td>
          {{ order.payment_status }}
        </td>
        <td>
          <button type="button" class="btn btn-light btn-sm waves-effect" (click)="onEditOrder(order)">
            <i class="mdi mdi-square-edit-outline me-1"></i> Edit
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- end table -->

<ng-template #orderview let-modal>
  <div class="modal-header border-bottom-0">
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"> </button>
  </div>
  <div class="modal-body">
    <div class="text-center mb-4">
      <div class="avatar-md mx-auto mb-4">
        <div class="avatar-title bg-light rounded-circle text-primary h1">
          <i class="mdi mdi-bottle-wine"></i>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-14">
          <h4 class="text-primary">OrderID: {{ this.orderId }} | {{ this.orderDate  | date: 'MMM d, y'  }} | {{ this.orderStatus }}</h4>
          <h4 class="text-primary">OrderNo: {{ this.extOrderNo }}</h4>
          <h4 class="text-primary">{{ this.custName }}</h4>
          <h4 class="text-primary">{{ this.pmtStatus }} | {{ this.total | currency:'USD':true:'1.2-2' }}</h4>
          <p class="text-muted font-size-14 mb-4"></p>
          <div class="table-responsive">
            <table class="table table-nowrap">
              <thead class="table-light">
              <tr>
                <th style="width: 70px;">Qty</th>
                <th>Item</th>
                <th class="text-end">Price</th>
              </tr>
              </thead>
              <tbody  *ngFor="let item of orderItems">
                <tr>
                  <td>{{ item.qty }}</td>
                  <td>{{ item.item_name}}</td>
                  <td class="text-end">{{ item.total  | currency:'USD':true:'1.2-2'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="this.orderStatus === 'Processing'" class="input-group bg-light rounded">
            <button class="btn btn-primary" type="button" id="button-addon2" (click)="onChangeOrderStatus(7)">
              <i class="bx bxs-badge-check"> Mark as ready </i>
            </button>
          </div>
          <div *ngIf="this.orderStatus === 'Ready'" class="input-group bg-light rounded">
            <button class="btn btn-primary" type="button" id="button-addon3" (click)="onChangeOrderStatus(8)">
              <i class="bx bxs-badge-check"> Picked up </i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
