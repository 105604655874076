<!-- start page title -->
<app-pagetitle title="Dashboard" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
  <div class="col-md-6 col-xl-3">
    <div class="card">
      <div class="card-body">
        <div class="float-end">
          <div class="avatar-sm mx-auto mb-4">
                        <span class="avatar-title rounded-circle bg-light font-size-24">
                            <i class="mdi mdi-calendar-clock text-success"></i>
                        </span>
          </div>
        </div>
        <div>
          <p class="text-muted text-uppercase fw-semibold">Orders In Process</p>
          <h4 class="mb-1 mt-1"><span class="counter-value">{{ ordersInProcess }}</span>
          </h4>
        </div>
        <p class="text-muted mt-3 mb-0"><span class="badge badge-soft-warning me-1"><i
          class="mdi mdi-arrow-up-bold me-1"></i>0.55%</span> since last month
        </p>
      </div>
    </div>
  </div> <!-- end col-->


  <div class="col-md-6 col-xl-3">
    <div class="card">
      <div class="card-body">
        <div class="float-end">
          <div class="avatar-sm mx-auto mb-4">
                        <span class="avatar-title rounded-circle bg-light font-size-24">
                            <i class="mdi mdi-cart-check text-success"></i>
                        </span>
          </div>
        </div>
        <div>
          <p class="text-muted text-uppercase fw-semibold">Orders Completed</p>
          <h4 class="mb-1 mt-1"><span class="counter-value"> {{ ordersCompleted }}</span>
          </h4>
        </div>
        <p class="text-muted mt-3 mb-0"><span class="badge badge-soft-warning me-1"><i
          class="mdi mdi-arrow-up-bold me-1"></i>0.55%</span> since last month
        </p>
      </div>
    </div>
  </div> <!-- end col-->


    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end">
                    <div class="avatar-sm mx-auto mb-4">
                        <span class="avatar-title rounded-circle bg-light font-size-24">
                            <i class="mdi mdi-cash-multiple text-primary"></i>
                        </span>
                    </div>
                </div>
                <div>
                    <p class="text-muted text-uppercase fw-semibold">Total Sales</p>
                    <h4 class="mb-1 mt-1">$<span class="counter-value"> {{ totalSales }} </span></h4>
                </div>
                <p class="text-muted mt-3 mb-0"><span class="badge badge-soft-success me-1"><i
                            class="mdi mdi-arrow-up-bold me-1"></i>2.65%</span> since last month
                </p>
            </div>
        </div>
    </div> <!-- end col-->

    <div class="col-md-6 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="float-end">
                    <div class="avatar-sm mx-auto mb-4">
                        <span class="avatar-title rounded-circle bg-light font-size-24">
                            <i class="mdi mdi-account-group text-primary"></i>
                        </span>
                    </div>
                </div>
                <div>
                    <p class="text-muted text-uppercase fw-semibold">Subscribed Users</p>
                    <h4 class="mb-1 mt-1"><span class="counter-value"> {{ totalSubscribers }}</span></h4>
                </div>
                <p class="text-muted mt-3 mb-0"><span class="badge badge-soft-success me-1"><i
                            class="mdi mdi-arrow-up-bold me-1"></i>14.33%</span> since last month
                </p>
            </div>
        </div>
    </div> <!-- end col-->


</div> <!-- end row-->

<!-- displaying active orders-->
<div class="row">
   <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="float-end">
                    <div class="dropdown" ngbDropdown>
                        <a class="dropdown-toggle text-reset" href="javascript:void(0);" ngbDropdownToggle
                            id="dropdownMenuButton4" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <span class="text-muted">Status:  </span> <span class="fw-semibold">Processing<i
                                    class="mdi mdi-chevron-down-box ms-1"></i></span>
                        </a>
                    </div>
                </div>
                <h4 class="card-title mb-4">Orders in Process</h4>
                <div class="table-responsive">
                    <app-order [orders]="orders"></app-order>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end row -->


<ng-template #content let-modal>
    <div class="modal-header border-bottom-0">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"> </button>
    </div>
    <div class="modal-body">
        <div class="text-center mb-8">
            <div class="avatar-md mx-auto mb-6">
                <div class="avatar-title bg-light rounded-circle text-primary h1">
                    <i class="mdi mdi-email-open"></i>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-10">
                    <h4 class="text-primary">Subscribe !</h4>
                    <p class="text-muted font-size-14 mb-4">Subscribe our newletter and get notification to stay update.
                    </p>
                    <div class="input-group bg-light rounded">
                        <input type="email" class="form-control bg-transparent border-0"
                            placeholder="Enter Email address" aria-label="Recipient's username"
                            aria-describedby="button-addon2">

                        <button class="btn btn-primary" type="button" id="button-addon2">
                            <i class="bx bxs-paper-plane"></i>
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>



